<template>
  <div class="file-manage">
    <statistics></statistics>
    <file-list></file-list>
    <edit-modal></edit-modal>
    <delete-modal></delete-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import statistics from '@/components/fileManage/Statistics'
import fileList from '@/components/fileManage/FileList'
import editModal from '@/components/fileManage/EditModal'
import deleteModal from '@/components/fileManage/DeleteModal'

export default {
  // inject: ['eventBus'],
  components: {
    statistics,
    fileList,
    editModal,
    deleteModal
  },
  computed: {
    ...mapGetters('user', [
      'isTeacher'
    ])
  },
  created () {
    if (this.isTeacher) {
      this.$router.back()
    }
  }
}
</script>

<style lang="less">
.file-manage {
  .total {
    margin: 0 -12px;
    .item {
      border: 1px solid #e7e7eb;
      background: linear-gradient(180deg, #ffffff, #fbfbfb);
      border-radius: 6px;
      margin: 0 12px;
      line-height: 1;
      .bd {
        padding: 16px;
        display: flex;
        .icon {
          width: 40px;
          height: 40px;
          margin-right: 12px;
          img {
            width: 100%;
          }
        }
        .tenu {
          .num {
            margin-top: 8px;
            margin-bottom: -3px;
            b {
              font-size: 30px;
            }
            span {
              font-size: 12px;
              font-weight: normal;
              margin-left: 4px;
            }
          }
        }
      }
    }
  }
  .fili {
    margin-top: 24px;
    border: 1px solid #e7e7eb;
    background: #fff;
    border-radius: 6px;
    padding: 0 16px;
    > .title {
      font-size: 20px;
      padding: 16px 0 12px;
    }
    .filter {
      border-radius: 2px;
      border: 1px solid #e7e7eb;
      padding: 8px 0;
      .item {
        padding: 6px 20px;
        display: flex;
        align-items: center;
        .title {
          font-weight: bold;
          margin-right: 24px;
        }
        .value {
          span {
            display: inline;
            margin-right: 24px;
            &:hover,
            &.active {
              color: #4283fc;
              cursor: pointer;
            }
          }
        }
      }
    }
    .fun {
      margin-top: 16px;
      .search {
        .ant-input {
          height: 44px;
        }
        .anticon {
          font-size: 18px;
        }
      }
      .button {
        margin-top: 16px;
        display: flex;
        align-items: center;
        .ant-btn {
          width: 80px;
          margin-right: 16px;
        }
      }
    }
    .list {
      .table {
        margin: 16px 0;
      }
    }
  }
}
.editinfo-model {
  .ant-form-item {
    margin-bottom: 32px;
    .ant-form-explain {
      position: absolute;
      top: 55px;
    }
  }
}
</style>
